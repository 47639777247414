// https://github.com/jshttp/cookie/blob/master/index.js
import isNumber from 'lodash.isnumber';

import {
  COOKIE_DOMAIN,
  GDPR_COOKIE_ACCEPTANCE_COUNTRY_TIME_ZONE_ID_MAP,
  GDPR_COOKIES_EXPIRES_IN_DAYS,
  GDPR_MANDATORY_COOKIE_NAME,
  GDPR_MARKETING_COOKIE_NAME,
  GDPR_PERFORMANCE_COOKIE_NAME,
  OLD_GDPR_COOKIE_NAME,
} from '@constants/cookieConstant';

const pairSplitRegExp = /; */;

const getCookieString = () => {
  return typeof window !== 'undefined' ? document.cookie : '';
};

const parseToJSON = val => {
  try {
    return JSON.parse(val);
  } catch (err) {
    return val;
  }
};

/**
 * parse a Cookie request header string
 */
const parseCookies = (cookieString = '') => {
  const cookies = {};
  const pairs = cookieString.split(pairSplitRegExp);

  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i];
    const eqIndex = pair.indexOf('=');

    const key = pair.slice(0, eqIndex).trim();
    let val = pair.slice(eqIndex + 1).trim();

    // quoted values
    if (val[0] === '"') {
      val = val.slice(1, -1);
    }

    if (key && cookies[key] === undefined) {
      cookies[key] = decodeURIComponent(val);
    }
  }

  return cookies;
};

/**
 * serialize a cookie name-value pair into a Set-Cookie response header string
 */
const serializeCookie = (name, val, options = {}) => {
  const { maxAge, domain, expires } = options;

  const value = encodeURIComponent(val);

  let str = `${name}=${value}`;

  if (maxAge) {
    if (!isNumber(maxAge)) {
      throw new TypeError('option maxAge is not a number');
    }

    str += `; Max-Age=${maxAge}`;
  }

  if (domain) {
    str += `; Domain=${domain}`;
  }

  if (expires) {
    const expiresDate =
      typeof expires === 'number'
        ? new Date(Date.now() + expires * 24 * 60 * 60 * 1000)
        : expires;
    str += `; Expires=${expiresDate.toUTCString()}`;
  }

  return str;
};

/**
 * check if cookie exists
 */
const hasCookie = name => {
  return Boolean(getCookie(name));
};

/**
 * get cookie value
 */
const getCookie = (name, options) => {
  const { cookieString = getCookieString() } = options || {};

  const cookies = parseCookies(cookieString);
  const cookie = cookies[name];

  return parseToJSON(cookie);
};

/**
 * set document.cookie with serialized cookie value
 */
const setCookie = (name, val, options) => {
  document.cookie = serializeCookie(name, val, options);
};

const removeCookie = (name, path, domain) => {
  setCookie(name, '', { expires: -1, path, domain });
};

const removeOldGdprCookie = () =>
  removeCookie(OLD_GDPR_COOKIE_NAME, undefined, COOKIE_DOMAIN);

const getMandatoryGdprCookie = () => {
  const oldGDPRCookie = getCookie(OLD_GDPR_COOKIE_NAME);
  if (oldGDPRCookie) {
    removeOldGdprCookie();
  }

  return getCookie(GDPR_MANDATORY_COOKIE_NAME);
};

const showCookiesPopup = () => {
  try {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    if (!timeZone) return true;

    const gdprAcceptedTimezones = Object.values(
      GDPR_COOKIE_ACCEPTANCE_COUNTRY_TIME_ZONE_ID_MAP
    ).flat();
    return gdprAcceptedTimezones.includes(timeZone);
  } catch (err) {
    return true;
  }
};

const setGdprCookie = cookiesState => {
  setCookie(GDPR_MANDATORY_COOKIE_NAME, `${cookiesState.necessary}`, {
    domain: COOKIE_DOMAIN,
    expires: GDPR_COOKIES_EXPIRES_IN_DAYS,
  });
  setCookie(GDPR_PERFORMANCE_COOKIE_NAME, `${cookiesState.performance}`, {
    domain: COOKIE_DOMAIN,
    expires: GDPR_COOKIES_EXPIRES_IN_DAYS,
  });
  setCookie(GDPR_MARKETING_COOKIE_NAME, `${cookiesState.marketing}`, {
    domain: COOKIE_DOMAIN,
    expires: GDPR_COOKIES_EXPIRES_IN_DAYS,
  });
};

const isMandatoryCookieAccepted = () => {
  if (!showCookiesPopup()) {
    setGdprCookie({ necessary: true, marketing: true, performance: true });
    return true;
  }

  return getMandatoryGdprCookie() === true;
};

const getPerformanceGdprCookie = () => getCookie(GDPR_PERFORMANCE_COOKIE_NAME);

const getMarketingGdprCookie = () => getCookie(GDPR_MARKETING_COOKIE_NAME);

const MAP_GDPR_COOKIE_STATE_WITH_LIBRARY = {
  SourceBuster: 'performance',
  Pardot: 'marketing',
};

const isCookieAccepted = cookie => {
  switch (cookie) {
    case 'performance':
      return getPerformanceGdprCookie() === true;
    case 'marketing':
      return getMarketingGdprCookie() === true;
    default:
      return getMandatoryGdprCookie() === true;
  }
};

const isGdprCookieAccepted = key => {
  const library = MAP_GDPR_COOKIE_STATE_WITH_LIBRARY[key];
  return isCookieAccepted(library);
};

export {
  getCookie,
  hasCookie,
  setCookie,
  parseToJSON,
  parseCookies,
  getCookieString,
  serializeCookie,
  removeCookie,
  removeOldGdprCookie,
  getMandatoryGdprCookie,
  showCookiesPopup,
  isMandatoryCookieAccepted,
  setGdprCookie,
  getPerformanceGdprCookie,
  getMarketingGdprCookie,
  isCookieAccepted,
  isGdprCookieAccepted,
};
