import { isGdprCookieAccepted } from '@utils/cookies';

import { initSourceBuster } from './utils';

export const initializeSourceBuster = domain => {
  const gdprCookie = isGdprCookieAccepted('SourceBuster');

  if (gdprCookie) {
    initSourceBuster(domain);
  }
};
