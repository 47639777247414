export const createScript = () => {
  const isHttps = document.location.protocol === 'https:';

  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = `${isHttps ? 'https://pi' : 'http://cdn'}.pardot.com/pd.js`;
  const c = document.getElementsByTagName('script')[0];
  c.parentNode.insertBefore(s, c);
};

export const initPardot = (delay, piAId, piCId, piHostname) => {
  if (!piAId || !piCId || !piHostname) {
    // Required Pardot initialization data not found, skip adding script to the page
    return;
  }

  if (window.piAId || window.piCId || window.piHostname) {
    // Looks like Pardot script might already be initialized, skip adding it to page
    return;
  }

  window.piAId = piAId;
  window.piCId = piCId;
  window.piHostname = piHostname;

  window.setTimeout(createScript, delay);
};
