import { isGdprCookieAccepted } from '@utils/cookies';

import { initPardot } from './utils';

export const initializePardot = (delay, piAId, piCId, piHostname) => {
  const gdprCookie = isGdprCookieAccepted('Pardot');

  if (gdprCookie) {
    initPardot(delay, piAId, piCId, piHostname);
  }
};
