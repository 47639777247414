export const GDPR_COOKIE_ACCEPTANCE_COUNTRY_TIME_ZONE_ID_MAP = {
  AT: ['Europe/Vienna'],
  EE: ['Europe/Tallinn'],
  IT: ['Europe/Rome'],
  PT: ['Atlantic/Azores', 'Atlantic/Madeira', 'Europe/Lisbon'],
  BE: ['Europe/Brussels'],
  FI: ['Europe/Helsinki'],
  LV: ['Europe/Riga'],
  RO: ['Europe/Bucharest'],
  BG: ['Europe/Sofia'],
  FR: ['Europe/Paris'],
  LT: ['Europe/Vilnius'],
  SK: ['Europe/Bratislava', 'Europe/Prague'],
  HR: ['Europe/Belgrade', 'Europe/Zagreb'],
  DE: ['Europe/Berlin', 'Europe/Busingen', 'Europe/Zurich'],
  LU: ['Europe/Brussels', 'Europe/Luxembourg'],
  SI: ['Europe/Belgrade', 'Europe/Ljubljana'],
  CY: ['Asia/Famagusta', 'Asia/Nicosia'],
  GR: ['Europe/Athens'],
  MT: ['Europe/Malta'],
  ES: ['Africa/Ceuta', 'Atlantic/Canary', 'Europe/Madrid'],
  CZ: ['Europe/Prague'],
  HU: ['Europe/Budapest'],
  NL: ['Europe/Amsterdam', 'Europe/Brussels'],
  SE: ['Europe/Berlin', 'Europe/Stockholm'],
  DK: ['Europe/Berlin', 'Europe/Copenhagen'],
  IE: ['Europe/Dublin'],
  PL: ['Europe/Warsaw'],
  GB: ['Europe/London'],
};
export const GDPR_COOKIES_EXPIRES_IN_DAYS = 180;
export const OLD_GDPR_COOKIE_NAME = 'cookiePrivacyAccepted';
export const GDPR_MANDATORY_COOKIE_NAME = 'mandatoryCookieTracker';
export const GDPR_PERFORMANCE_COOKIE_NAME = 'performanceCookieTracker';
export const GDPR_MARKETING_COOKIE_NAME = 'marketingCookieTracker';
export const COOKIE_DOMAIN = `${process.env.GATSBY_COOKIE_DOMAIN}`;
